import $ from 'jquery';
import 'jquery-mask-plugin';

document.addEventListener('DOMContentLoaded', () => {

  const applyMasks = () => {
    $('[data-mask-phone]').mask('(00) 00000-0000');
  };

  applyMasks()
  const form = document.querySelector('form');
  if (form) {
    form.addEventListener('submit', () => {
      const phoneField = document.querySelector('[data-mask-phone]');
      if (phoneField) {
        phoneField.value = phoneField.value.replace(/\D/g, '');
      }
    });
  }
});

$(() => {
  $("#admins-table").DataTable({
    "dom": '<"d-flex flex-row"l><"ml-auto"f>rt<"d-flex flex-row"i><"ml-auto"p>',
    "columnDefs": [{
      "targets": [3, 4],
      "orderable": false
    }],
    language: {
      url:
        'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
    },
  });
});

$(function () {
  let url = '/stores';

  fetch(url)
  .then((resp) => resp.json())
  .then(function(data) {
    store_select = $('#panel_admin_store_codes');
    data.store_codes.forEach(function(store_code){
      store_select.append($('<option>', {
        value: store_code,
        text: store_code
      }));
    });
  })
  .catch(function(error) {
    console.log(error);
  });
});